/*
* ===================================================
*     Magnific Popup
* ===================================================
*/

$mfpColorEscaped: escape-for-svg($mfpColor);

button.mfp-arrow,
button.mfp-close {
    opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
    border: none;
}

.mfp-arrow:before {
    width: 45px;
    height: 45px;
    opacity: .9;
    background: center center url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'#{$mfpColorEscaped}'%2F%3E%3C%2Fsvg%3E") no-repeat;
}

.mfp-arrow-left:before {
    margin-left: 35px;
}

.mfp-arrow-right:before {
    margin-left: 0px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'#{$mfpColorEscaped}'%2F%3E%3C%2Fsvg%3E");
}

.mfp-container {
    button:focus {
        outline: none;
    }
}