// Bootstrap Select
$color-red-error: rgb(185, 74, 72) !default;
$color-green-success: #28a745;
$color-grey-arrow: rgba(204, 204, 204, 0.2) !default;
$width-default: 220px !default; // 3 960px-grid columns
$zindex-select-dropdown: 1060 !default; // must be higher than a modal background (1050)
$input-color-placeholder: #999 !default;
$input-alt-color-placeholder: rgba(255, 255, 255, 0.5) !default;

// Toggle 
$switch-height: calc(#{$input-height} * .8) !default;
$switch-height-sm: calc(#{$input-height-sm} * .8) !default;
$switch-height-lg: calc(#{$input-height-lg} * .8) !default;
$switch-border-radius: $switch-height !default;
$switch-bg: $gray-300 !default;
$switch-checked-bg: $primary !default;

$switch-thumb-bg: $white !default;
$switch-thumb-border-radius: 50% !default;
$switch-thumb-padding: 2px !default;
$switch-focus-box-shadow: 0 0 0 $input-btn-focus-width rgba($primary, .25);
$switch-transition: .2s all !default;

// Swiper
$swiperColor: $primary;

// Magnific Popup - This colour needs to be escaped (%23 = #)
$mfpColor: $white;