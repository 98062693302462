/*
* ===================================================
*    Custom Nav Pills
* ===================================================
*/
.nav-pills-custom {
    flex-wrap: wrap;

    .nav-link {
        margin: 10px;
        color: map-get($theme-colors,"primary");
        border: 1px solid map-get($theme-colors,"primary");
        border-radius: 50px;

        &.active,
        &:hover,
        &:focus {
            color: #fff;
            background: map-get($theme-colors,"primary");
        }

        &:first-of-type {
            margin-left: 0;
        }
    }
}